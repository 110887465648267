<template>
  <v-container fluid class="pt-0" style="height: 100vh;">
    <v-row justify="center" align="center" style="height: 100%">
      <v-col cols="12">
        <v-card max-width="700" class="mx-auto" elevation="2">
          <v-card-title style="font-size: 1.2rem; text-transform: uppercase" class="py-5">
            Експорт поданих показів в особистому кабінеті
          </v-card-title>
          <v-card-text class="pt-0">
            <v-row>
              <v-col cols="12" md="6">
                <date-component monthly
                                v-model="month"
                                label="Місяць здачі бази"
                                :class_="!month ? 'req-star' : ''"
                                req
                />
              </v-col>
              <v-col cols="6">
                <v-checkbox color="secondary"
                            hide-details
                            v-model="csv_format"
                            class="mt-2"
                            label="Формат CSV"/>
              </v-col>
              <v-col cols="6">
                <date-component
                    label="Період з"
                    v-model="date_start"
                    :class_="!date_start ? 'req-star' : ''"
                    req
                />
              </v-col>
              <v-col cols="6">
                <date-component
                    label="Період по"
                    v-model="date_end"
                    :class_="!date_end ? 'req-star' : ''"
                    req
                />
              </v-col>
              <v-col cols="12">
                <v-select
                    label="Послуга"
                    hide-details
                    v-model="service_id"
                    :items="services"
                    :class="!service_id ? 'req-star' : ''"
                    filled color="grey"
                />
              </v-col>
            </v-row>

          </v-card-text>
          <v-card-text class="pt-3 pb-1">
            <v-btn block class="success--text mb-3" @click="genMeterReadings"
                   :disabled="!month || !date_start || !date_end || !service_id" :loading="loading">
              Експортувати
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import epsAPI from "@/utils/axios/eps";
import {ALERT_SHOW} from "@/store/actions/alert";
import {mapActions, mapGetters} from "vuex";
import {SELECT_SERVICES_WITH_COUNTER} from "@/store/actions/services";

export default {
  name: "ExportCounterReading",
  components: {

  },
  data() {
    return {
      month: null,
      date_start: null,
      date_end: null,
      service_id: null,
      csv_format: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      organization: 'getCurrentOrganization',
      current_month: 'getCurrentMonth',
      services: 'getServicesSelectWithCounter'
    })
  },
  methods: {
    ...mapActions({
      fetchServices: SELECT_SERVICES_WITH_COUNTER,
    }),
    genMeterReadings() {
      if (!this.month || !this.service_id || !this.date_start || !this.date_end) {
        this.$store.commit(ALERT_SHOW, {text: 'Місяць, Період з, Період по та Послуга мають бути заповнені', color: 'error'})
        return
      }
      const payload = {
        month: this.month,
        date_start: this.date_start,
        date_end: this.date_end,
        service_id: this.service_id,
        csv: this.csv_format,
      }
      this.loading = true
      epsAPI.generate_meter_reading(payload)
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a');
            const file_name = this.csv_format
                ? `${this.organization.code||'export'}_readings.csv`
                : `${this.organization.code||'export'}_readings.dbf`
            link.href = url;
            link.setAttribute('download', file_name); //or any other extension
            document.body.appendChild(link);
            link.click();
            this.loading = false
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            this.loading = false
          })
    },
  },
  created() {
    this.month = this.current_month
    this.fetchServices()
  }
}
</script>

<style scoped lang="scss">
</style>